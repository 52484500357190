export enum WidgetEventName {
  BEFORE_LOAD = 'beforeLoad',
  AFTER_LOAD = 'afterLoad',
  BEFORE_CREATE = 'beforeCreate',
  AFTER_CREATE = 'afterCreate',
  BEFORE_RENDER = 'beforeRender',
  AFTER_RENDER = 'afterRender',

  FETCH_PAYMENT_DATA_START = 'fetchPaymentDataStart',
  FETCH_PAYMENT_DATA_END = 'fetchPaymentDataEnd',

  PURPOSE_CHANGED = 'purposeChanged',
  AMOUNT_CHANGED = 'amountChanged',
  CURRENCY_CHANGED = 'currencyChanged',
  PAYMENT_TYPE_CHANGED = 'paymentTypeChanged',
  RECURRING_INTERVAL_CHANGED = 'recurringIntervalChanged',
  PAYMENT_METHOD_CHANGED = 'paymentMethodChanged',

  BEFORE_PAYMENT_VALIDATE_AND_SEND = 'beforePaymentValidateAndSend',
  BEFORE_PAYMENT_VALIDATE = 'beforePaymentValidate',
  AFTER_PAYMENT_VALIDATE = 'afterPaymentValidate',
  PAYMENT_VALIDATE_SUCCESS = 'validatePaymentSuccess',
  PAYMENT_VALIDATE_ERROR = 'paymentValidateError',

  BEFORE_PAYMENT_SEND = 'beforePaymentSend',
  PAYMENT_COMPLETE = 'paymentComplete',

  SUBSCRIPTION_UPDATE_COMPLETE = 'subscriptionUpdateComplete',
  SUBSCRIPTION_CANCELLED = 'subscriptionCancelled',
}

///////////////////////////////////////////////////////////////////////////////

export type WidgetEvent = {
  eventName: WidgetEventName
  data?: any
}

export type WidgetEventHandler = (event: WidgetEvent) => void

export type WidgetEvents = {
  [key in WidgetEventName]?: WidgetEventer
}

///////////////////////////////////////////////////////////////////////////////

export class WidgetEventer {
  private eventName: WidgetEventName
  private handlers: WidgetEventHandler[] = []

  constructor(eventName: WidgetEventName) {
    this.eventName = eventName
  }

  async publish(data?: any) {
    let event: WidgetEvent = {eventName: this.eventName}

    if (data) {
      event.data = data
    }

    for (let handler of this.handlers) {
      await handler(event)
    }
  }

  subscribe(handler: WidgetEventHandler) {
    this.handlers.push(handler)

    return () => {
      let idx = this.handlers.indexOf(handler)

      if (idx !== -1) {
        this.handlers.splice(idx, 1)
      }
    }
  }
}

///////////////////////////////////////////////////////////////////////////////

export function createEvents(): WidgetEvents {
  let result = {} as WidgetEvents

  for (let eventName of Object.values(WidgetEventName)) {
    result[eventName] = new WidgetEventer(eventName as WidgetEventName)
  }

  return result
}
