import {createWidgetPreloader} from '../source/preloader'
import {WidgetEvent, WidgetEventName} from '../source/tamaro/lib/WidgetEventer'

///////////////////////////////////////////////////////////////////////////////

let widgetPreloader = createWidgetPreloader({
  resolvers: {
    initialConfig: async () => {
      return {
        customConfig: require('../config/config.**.yml'),
      }
    },
    preloaderCSS: () => import('./styles/preloader'),
    widgetCSS: () => import('./styles/widget'),
  },
})

///////////////////////////////////////////////////////////////////////////////

// Expose global variables if needed
widgetPreloader.events[WidgetEventName.AFTER_CREATE].subscribe((event: WidgetEvent) => {
  if (event.data && event.data.api) {
    window['api'] = event.data.api
  }
})

///////////////////////////////////////////////////////////////////////////////

window['rnw'] = window['rnw'] || {}
window['rnw']['tamaro'] = widgetPreloader
